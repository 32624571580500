.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;

  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sale {
    text-align: center;
    align-items: center;
    justify-content: center;
    h1 {
      font-family: Arial, Helvetica, sans-serif;
      align-items: center;
      justify-content: center;
    }
    h2 {
      font-family: Arial, Helvetica, sans-serif;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: underline;
        color: inherit;
      }
    }
    p {
      font-family: Arial, Helvetica, sans-serif;
      align-items: center;
      justify-content: center;
    }
  }
}
